'use client';

import { COMING_SOON_MODE } from '@/src/services/util.service';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import styles from './styles.module.css';

const companyTab = [
  {
    id: 1,
    labelName: 'About Us',
    url: '/about-us',
  },
  {
    id: 2,
    labelName: 'Blogs',
    url: '/blogs',
  },
  {
    id: 3,
    labelName: 'FAQs',
    url: '/faq',
  },
  {
    id: 4,
    labelName: 'Contact Us',
    url: '/contact-us',
    hideComingSoon: true,
  },
];

export default function NavBarLinks({
  className,
  animate,
  isDarkMode = false,
  setOpen,
}: {
  className: string;
  animate: boolean;
  isDarkMode?: boolean;
  setOpen?: (arg: boolean) => void;
  open?: boolean;
}) {
  const [openTab, setOpenTab] = useState(false);
  const pathname = usePathname();
  const navRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const openCompanyTab = () => {
    setOpenTab(!openTab);
  };

  const companyLabel = () => {
    return (
      <div
        className="flex justify-start items-end h-full"
        onClick={openCompanyTab}
      >
        <div>Company</div>
        <IoIosArrowDown className="h-5 w-5 text-royal-blue ml-1" />
      </div>
    );
  };

  const tabs = [
    {
      id: 1,
      labelName: 'Home',
      showDropDown: false,
      url: '/',
    },
    {
      id: 2,
      labelName: 'Features',
      showDropDown: false,
      url: '/features',
    },
    {
      id: 3,
      labelName: 'Cube',
      showDropDown: false,
      url: '/cube',
    },
    {
      id: 4,
      labelName: 'Journey',
      showDropDown: false,
      url: '/journey',
    },
    {
      id: 5,
      labelName: 'Pricing',
      showDropDown: false,
      url: '/pricing',
      hideComingSoon: true,
    },
    {
      id: 6,
      labelName: companyLabel(),
      showDropDown: true,
      url: '#',
    },
  ];

  useEffect(() => {
    const handleClickOutSide = (event: MouseEvent) => {
      if (navRef.current && !navRef.current.contains(event.target as Node)) {
        setOpenTab(false);
      }
    };
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', handleClickOutSide);
      return () => {
        document.removeEventListener('mousedown', handleClickOutSide);
      };
    }
  }, []);

  const navigateToCompanyTab = (url: string) => {
    setOpen?.(false);
    setOpenTab(false);
    router.push(url);
  };

  const navigateToTab = (id: number) => {
    if (id !== 6) {
      setOpenTab(false);
      setOpen?.(false);
    }
  };

  return (
    <nav className={className} ref={navRef}>
      {tabs.map((data) =>
        COMING_SOON_MODE && data?.hideComingSoon ? null : (
          <Link
            key={data?.url}
            href={data?.url}
            className={clsx(
              pathname === data?.url
                ? 'text-blue-700 active md:underline md:decoration-blue-700 md:underline-offset-8 md:decoration-2'
                : animate
                ? styles.links
                : isDarkMode
                ? 'text-white'
                : 'text-zinc-800',
              ' text-opacity-75',
              'text-sm font-medium md:font-normal leading-normal md:px-1'
            )}
            onClick={() => navigateToTab(data.id)}
          >
            {data?.labelName}
          </Link>
        )
      )}

      {openTab && (
        <>
          <div className="md:hidden space-y-5 flex flex-col">
            {companyTab?.map((data) =>
              COMING_SOON_MODE && data?.hideComingSoon ? null : (
                <Link
                  key={data?.url}
                  href={data?.url}
                  className={clsx(
                    pathname === data?.url
                      ? ' text-blue-700 active'
                      : animate
                      ? styles.links
                      : isDarkMode
                      ? 'text-white'
                      : 'text-zinc-800',
                    ' text-opacity-75',
                    'text-sm font-medium leading-normal pl-2'
                  )}
                  onClick={() => navigateToTab(data.id)}
                >
                  {data?.labelName}
                </Link>
              )
            )}
          </div>
          <div className="hidden md:block absolute top-10 right-0 bg-[#f4f4f4] h-auto w-auto shadow-lg text-zinc-800 p-2 rounded-md">
            <ul className="space-y-3">
              {companyTab?.map((data) =>
                COMING_SOON_MODE && data?.hideComingSoon ? null : (
                  <li
                    className="hover:text-blue-700 cursor-pointer text-sm space-y-3"
                    key={data.id}
                    onClick={() => navigateToCompanyTab(data.url)}
                  >
                    {data.labelName}
                  </li>
                )
              )}
            </ul>
          </div>
        </>
      )}
    </nav>
  );
}
